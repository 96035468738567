import React, { lazy, Suspense, useState } from 'react';
import PropTypes from 'prop-types';
// this must be import differently https://react-hookz.github.io/web/?path=/docs/side-effect-useCookieValue--example
import { useCookieValue } from '@react-hookz/web/esm/useCookieValue';
import { useMountEffect } from '@react-hookz/web';
import { v4 as uuidv4 } from 'uuid';
import { useForm, FormProvider } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useSegment } from '@/hooks/useSegment';
import kyWithCustomer from '@/auth/kyWithCustomer';
import { Backdrop, CircularProgress, Stack, Typography, useMediaQuery } from '@mui/material';
import { useCustomerStorage } from '@/hooks/useCustomerStorage';
import { useSteps } from './useSteps';
import { useGeolocated } from 'react-geolocated';
import Loader from './components/Loader';

const ResultPage = lazy(() => import('./components/ResultPage'));

const formatPayload = ({ agrodealer, ward, county, phoneNumber, basket, paymentMethod, ...rest }) => ({
  agrodealerId: Number(agrodealer.id),
  wardId: Number(ward.id),
  countyId: Number(county.id),
  phoneNumber: phoneNumber.replace(/^0/, '+254'),
  bundles: Object.entries(basket)
    .filter(([, answerJson]) => answerJson)
    .map(([bundleTag, answerJson]) => ({ bundleTag, answerJson })),
  ...rest
});

const SignupCostumerContext = React.createContext();

const SignupCostumerContextProvider = ({ children, variant, exp, defaultValues }) => {
  const [featureFlag, setFeatureFlag] = useState(variant);
  const steps = useSteps({ featureFlag });
  const [currentTab, setCurrentTab] = useState('shop');
  const [storage, setStorage] = useCustomerStorage();
  const { trackEvent, identifyUser, reset } = useSegment();
  const [customerSessionId, updateCookie] = useCookieValue('customer-session-id');
  const [currentStep, setCurrentStep] = useState(0);
  const [isLocationShared, setIsLocationShared] = useState(false);
  const [resultPage, setResultPage] = useState();

  const formMethods = useForm({
    mode: 'onChange',
    defaultValues: {
      firstName: storage.firstName || '',
      lastName: storage.lastName || '',
      phoneNumber: storage.phoneNumber || '',
      confirmPhoneNumber: storage.confirmPhoneNumber || '',
      county: storage.county || null,
      ward: storage.ward || null,
      agrodealer: storage.agrodealer || null,
      preferredCrops: storage.preferredCrops || [],
      paymentMethod: storage.paymentMethod || null,
      enrollerType: null,
      // this will be converted into bundles=[...] before submitting
      basket: storage.basket || {},
      ...defaultValues
    }
  });
  const { handleSubmit, getValues, setValue } = formMethods;
  const [hasError, setHasError] = useState(null);
  const isDesktop = useMediaQuery(theme => theme.breakpoints.up('sm'));

  const basket = getValues('basket');

  const { coords } = useGeolocated({
    watchLocationPermissionChange: true
  });

  useQuery(
    ['get_customer_location'],
    () =>
      kyWithCustomer
        .get('/api/products/54/region', {
          searchParams: { long: coords.longitude, lat: coords.latitude, cashSale: false }
        })
        .json(),
    {
      onSuccess: res => {
        const { latitude, longitude } = coords;
        trackEvent('Location shared', {
          status: 'success',
          coords: { latitude, longitude },
          info: {
            wardId: res.wardInfo.id,
            wardName: res.wardInfo.name,
            countyId: res.wardInfo.countyId,
            countyName: res.wardInfo.countyName,
            inService: res.inService
          }
        });
        setValue(
          'countyByPosition',
          { id: res.wardInfo.countyId, name: res.wardInfo.countyName },
          { shouldValidate: true }
        );
        setValue('wardByPosition', { id: res.wardInfo.id, name: res.wardInfo.name }, { shouldValidate: true });
        setValue('agrodealer', null, { shouldValidate: true });
        setValue('agrodealersByPosition', res.agrodealers);
      },
      onError: err => {
        const { latitude, longitude } = coords;
        trackEvent('Location shared', { status: 'error', coords: { latitude, longitude }, errorMessage: err.message });
      },
      onSettled: () => {
        const { latitude, longitude } = coords;
        setValue('location', { latitude, longitude });
      },
      enabled: Boolean(coords)
    }
  );

  const { mutate: onSubmit, isLoading: isSubmitting } = useMutation(
    data => {
      if (featureFlag === 'cash') {
        return kyWithCustomer
          .post('/api/products/54/farmer_direct_cash_sale', {
            json: formatPayload(data),
            retry: 0
          })
          .json();
      }
      return kyWithCustomer
        .post('/api/farmer_direct_lead', {
          json: formatPayload(data),
          retry: 0
        })
        .json();
    },
    {
      onSuccess: res => {
        const { agentId, farmerId } = res;
        identifyUser(farmerId);
        if (window.clarity) window.clarity('identify', farmerId);
        trackEvent(
          'FDS Signup',
          { customerSessionId, status: 'success', data: { agentId, farmerId } },
          setResultPage({ status: 'success', data: res, variant: featureFlag })
        );
      },
      onError: err => {
        trackEvent(
          'FDS Signup',
          { customerSessionId, status: 'error', errorMessage: err.message },
          setResultPage({ status: 'error', data: err })
        );
      },
      onMutate: () => {
        trackEvent(`${steps[currentStep].name} Completed`, {
          ...getValues(),
          basket: JSON.stringify(basket),
          customerSessionId,
          formSubmitted: true
        });
      },
      onSettled: () => {
        reset();
      }
    }
  );

  const nextStep = () => {
    setStorage({ ...storage, ...getValues() });
    trackEvent(
      `${steps[currentStep].name} Completed`,
      { ...getValues(), basket: JSON.stringify(basket), customerSessionId },
      () => {
        setCurrentStep(p => p + 1);
      }
    );
  };
  const prevStep = () => {
    trackEvent('Back Button Clicked', { from: steps[currentStep].name, to: steps[currentStep - 1].name }, () => {
      setCurrentStep(p => p - 1);
    });
  };

  const resetStep = () => {
    setCurrentStep(0);
  };

  useMountEffect(() => {
    if (customerSessionId === null) {
      const newId = uuidv4();
      updateCookie(newId);
      if (window.clarity) window.clarity('identify', null, newId);
      setStorage({ ...storage, customerSessionId: newId });
    } else {
      if (window.clarity) window.clarity('identify', null, customerSessionId);
      setStorage({ ...storage, customerSessionId });
    }
  });

  return (
    <SignupCostumerContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        steps,
        currentStep,
        nextStep,
        prevStep,
        resetStep,
        setCurrentStep,
        setHasError,
        hasError,
        isLocationShared,
        setIsLocationShared,
        isSubmitting,
        submitForm: () => {
          if (featureFlag === 'cash') {
            return handleSubmit(onSubmit(getValues()));
          }

          return handleSubmit(onSubmit(getValues()));
        },
        currentTab,
        setCurrentTab,
        isDesktop,
        setResultPage,
        featureFlag,
        setFeatureFlag,
        exp
      }}
    >
      <Backdrop open={isSubmitting}>
        <Stack gap={16} alignItems="center">
          <CircularProgress color="primary" thickness={3} size={100} />
          <Typography color="primary" variant="demiBold">
            Please wait...
          </Typography>
        </Stack>
      </Backdrop>

      <FormProvider {...formMethods}>
        <Suspense fallback={<Loader />}>
          {resultPage ? <ResultPage {...resultPage} /> : <form autoComplete="off">{children}</form>}
        </Suspense>
      </FormProvider>
    </SignupCostumerContext.Provider>
  );
};

SignupCostumerContextProvider.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.string,
  defaultValues: PropTypes.object,
  exp: PropTypes.string
};

export { SignupCostumerContext, SignupCostumerContextProvider };
