import React from 'react';
import theme from './theme';
import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { SignupCostumerContextProvider } from './context';
import { QueryClient, QueryClientProvider } from 'react-query';

const queryConfig = { defaultOptions: { queries: { refetchOnWindowFocus: false, retry: false } } };
const queryClient = new QueryClient(queryConfig);

const CurrentStep = React.lazy(() => import('./components/CurrentStep'));

import { BrowserRouter, Route, Redirect } from 'react-router-dom';
import { useMountEffect } from '@react-hookz/web';
import { useSegment } from '@/hooks/useSegment';
import { useNetworkManager } from '@/hooks/useNetworkManager';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { ErrorBoundary } from '@sentry/react';

const App = () => {
  const { isConnectionSlow } = useNetworkManager();
  const { trackLanding } = useSegment();

  useMountEffect(() => {
    trackLanding(isConnectionSlow ? 'lite' : 'default');
    // remove spinner in index.html
    const initialLoader = document.getElementById('initial-loading');
    if (initialLoader) {
      initialLoader.remove();
    }
  });

  const forceRefresh = async () => {
    if (window?.caches) {
      const { caches } = window;
      const cacheNames = await caches.keys();
      const cacheDeletionPromises = cacheNames.map(n => caches.delete(n));

      await Promise.all(cacheDeletionPromises);
    }
    window.location.reload(true);
  };

  const failedToFetchRegex = new RegExp(/failed to fetch/i);

  return (
    <QueryClientProvider client={queryClient}>
      <MuiThemeProvider theme={theme}>
        <EmotionThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <BrowserRouter basename="/commercial">
              <ErrorBoundary
                onError={error => {
                  if (failedToFetchRegex.test(error.message)) {
                    forceRefresh();
                  }
                }}
              >
                <Route
                  exact
                  path="/"
                  render={() => (
                    <SignupCostumerContextProvider defaultValues={{ county: { id: 45 }, ward: { id: 1373 } }}>
                      <CurrentStep />
                    </SignupCostumerContextProvider>
                  )}
                />
                <Route path="*" render={() => <Redirect to={{ pathname: '/', search: location.search }} />} />
              </ErrorBoundary>
            </BrowserRouter>
          </LocalizationProvider>
        </EmotionThemeProvider>
      </MuiThemeProvider>
    </QueryClientProvider>
  );
};

export default App;
