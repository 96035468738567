import { noop } from 'lodash-es';
import { useCookieValue } from '@react-hookz/web/esm/useCookieValue';
import { format } from 'date-fns';

const isEnabled = Boolean(window.analytics);

export const useSegment = () => {
  const [customerSessionId] = useCookieValue('customer-session-id');

  const getUser = () => {
    if (isEnabled) {
      try {
        return window.analytics.user();
      } catch (error) {
        console.error(error);
      }
    }
    return null;
  };

  const identifyUser = (id, data = {}) => {
    if (isEnabled) {
      try {
        window.analytics.identify(id, { ...data, customerSessionId });
      } catch (error) {
        console.error(error);
      }
    }
  };

  const reset = () => {
    if (isEnabled) {
      try {
        window.analytics.reset();
      } catch (error) {
        console.error(error);
      }
    }
  };

  const trackEvent = (eventName, data = {}, callback = noop) => {
    if (isEnabled) {
      try {
        window.analytics
          .track(eventName, { ...data, customerSessionId })
          .then(callback)
          .catch();
      } catch (error) {
        console.error(error);
        callback();
      }
    } else {
      console.group(`${format(Date.now(), 'HH:mm::ss:SSS')} | MOCK SEGMENT EVENT - ${eventName}`);
      console.table({ ...data, customerSessionId });
      console.groupEnd();
      callback();
    }
  };

  const trackFDSPage = pageName => {
    if (isEnabled) {
      try {
        window.analytics.page('FDS Page', pageName, { customerSessionId });
      } catch (error) {
        console.error(error);
      }
    } else {
      console.group(`${format(Date.now(), 'HH:mm::ss:SSS')} | MOCK SEGMENT PAGE`);
      console.table(pageName);
      console.groupEnd();
    }
  };

  const trackLanding = dataMode => {
    if (isEnabled) {
      try {
        window.analytics.page('FDS Landing page', null, { dataMode, customerSessionId });
      } catch (error) {
        console.error(error);
      }
    } else {
      console.group(`${format(Date.now(), 'HH:mm::ss:SSS')} | MOCK SEGMENT PAGE - FDS Landing page`);
      console.table(dataMode);
      console.groupEnd();
    }
  };

  return { trackEvent, trackFDSPage, getUser, identifyUser, reset, trackLanding };
};
