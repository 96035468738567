import React from 'react';
import * as Sentry from '@sentry/react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import { getEnv, isProduction, isStaging } from '@/util/env';

const enableSentry = isProduction() || isStaging();

if (enableSentry) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN_COMMERCIAL,
    environment: `${getEnv()}-commercial`,
    integrations: [
      new Sentry.BrowserTracing({
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['localhost', /^https:\/\/(?:staging|production)\.apolloagriculture\.com\/ui/]
      }),
      new Sentry.Integrations.Breadcrumbs({
        console: false
      }),
      new Sentry.Replay()
    ],
    // Performance Monitoring
    tracesSampleRate: 0.1, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    ignoreErrors: [
      /Not allowed to load local resource/,
      /Oops! We couldn't recognize your location/,
      /Oops! It seems Apollo doesn't operate in/,
      /failed to fetch/i,
      /The provided geolocation provider is invalid/
    ]
  });
}

document.body.appendChild(Object.assign(document.createElement(`div`), { id: 'root_commercial' }));

ReactDOM.render(<App />, document.getElementById('root_commercial'));
