import React, { lazy, useEffect, useState } from 'react';

const NameForm = lazy(() => import('./forms/NameForm'));
const PhoneNumberForm = lazy(() => import('./forms/PhoneNumberForm'));
// const LocationForm = lazy(() => import('./forms/LocationForm'));
// const CropSelectionForm = lazy(() => import('./forms/CropSelectionForm'));
const PaymentMethodForm = lazy(() => import('./forms/PaymentMethodForm'));
const CountyWardForm = lazy(() => import('./forms/CountyWardForm'));
const AgrodealerForm = lazy(() => import('./forms/AgrodealerForm'));
const ShoppingExp = lazy(() => import('./forms/ShoppingExp'));
const ReviewOrder = lazy(() => import('./forms/ReviewOrder'));
// const NationalIdForm = lazy(() => import('./forms/NationalIdForm'));
const EnrollerForm = lazy(() => import('./forms/EnrollerForm'));

const initialSteps = [
  { render: () => <ShoppingExp />, fullscreen: true, name: 'Product Selection' },
  {
    render: () => <EnrollerForm />,
    name: 'Enroller Form',
    hideNext: true
  },
  {
    render: () => <NameForm textVariant="cash" />,
    name: 'Name Form'
  },
  {
    render: () => <PhoneNumberForm />,
    name: 'Phone Number Form'
  },
  {
    render: () => <CountyWardForm />,
    name: 'Location Form'
  },
  {
    render: () => <AgrodealerForm />,
    name: 'Agrodealer Form'
  },
  { render: () => <PaymentMethodForm />, name: 'Payment Method Form' }
];

export const useSteps = ({ featureFlag }) => {
  const [steps, setSteps] = useState(initialSteps);

  useEffect(() => {
    switch (featureFlag) {
      case 'cash':
        setSteps([...initialSteps, { render: () => <ReviewOrder />, fullscreen: true, name: 'Review Order' }]);
        break;
      case 'loan':
        setSteps(initialSteps);
        break;
      default:
        break;
    }
  }, [featureFlag]);

  return steps;
};
