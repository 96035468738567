import { createTheme } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import { ExpandMore } from '@mui/icons-material';

let theme = createTheme({
  spacing: 2,
  palette: {
    primary: {
      main: '#fad264',
      light: '#C1DEBA',
      medium: '#4d7d43',
      dark: '#f7bc1d',
      title: '#5F8D40'
    },
    secondary: {
      main: '#9E1A76',
      light: '#837ebb',
      dark: '#661148'
    },
    white: { main: '#F9F4EF', contrastText: '#1B3838' },
    black: { main: '#1B3838', contrastText: '#F9F4EF' }
  },
  // need to define all (default) breakpoints if we want to create custom ones
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      // call center laptops common screen width
      cc: 1292,
      xl: 1536
    }
  },
  typography: {
    fontFamily: ['Greycliff CF', 'Helvetica', 'Arial', 'sans-serif'],
    question: {
      fontFamily: 'Greycliff CF Bold',
      fontSize: '30px',
      lineHeight: '32px',
      fontWeight: 700,
      textAlign: 'center'
    },
    subquestion: {
      fontFamily: 'Greycliff CF Demi Bold',
      textAlign: 'center'
    },
    underline: {
      fontFamily: 'Greycliff CF Demi Bold',
      fontSize: 12,
      textDecoration: 'underline',
      cursor: 'pointer'
    },
    regular: {
      fontFamily: 'Greycliff CF'
    },
    demiBold: {
      fontFamily: 'Greycliff CF Demi Bold'
    },
    bold: {
      fontFamily: 'Greycliff CF Bold'
    }
  }
});

// define components overrides after we define global theme

theme = createTheme(theme, {
  components: {
    // this makes all checkbox labels smaller than default, in order to align it to the smaller sqaure
    MuiFormControlLabel: {
      defaultProps: {
        componentsProps: {
          typography: {
            fontSize: 12
          }
        }
      }
    },
    MuiCheckbox: {
      defaultProps: {
        size: 'small'
      },
      styleOverrides: {
        root: {
          paddingRight: 4
        }
      }
    },
    MuiTextField: {
      defaultProps: {
        size: 'small',
        disableUnderline: true
      }
    },
    MuiInputBase: {
      variants: [
        {
          props: { variant: 'inverted' },
          style: {
            borderColor: '#F3EADF',
            color: '#F3EADF'
          }
        }
      ],
      styleOverrides: {
        root: {
          background: 'transparent',
          border: '3px solid #1B3838',
          borderRadius: '50px',
          padding: '8px 16px',
          ':hover': {
            borderColor: theme.palette.primary.dark
          }
        },
        input: {
          '&::placeholder': {
            fontFamily: 'Greycliff CF Bold',
            fontSize: '16px',
            opacity: 0.75
          },
          fontFamily: 'Greycliff CF',
          fontSize: '16px'
        }
      }
    },
    MuiOutlinedInput: {
      defaultProps: { notched: true, disableUnderline: true }
    },
    MuiInputLabel: {
      defaultProps: {
        size: 'small',
        shrink: true
      }
    },
    MuiSelect: {
      defaultProps: {
        notched: true,
        IconComponent: ExpandMore
      },
      styleOverrides: {
        root: {
          background: 'transparent',
          border: '3px solid #1B3838',
          borderRadius: '50px',
          padding: '4px',
          ':hover': {
            borderColor: theme.palette.primary.dark
          }
        },
        notchedOutline: {
          background: 'transparent',
          border: '3px solid #1B3838',
          borderRadius: '50px',
          padding: '4px',
          ':hover': {
            borderColor: theme.palette.primary.dark
          }
        },
        icon: {
          color: 'black'
        }
      }
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained'
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: '100px',
          boxShadow: '0px 3px 1px 1px rgba(247,188,29,1)',
          fontFamily: 'Greycliff CF Bold'
        }
      },
      variants: [
        {
          props: { variant: 'text' },
          style: {
            border: 'none',
            boxShadow: theme.shadows[0]
          }
        },
        {
          props: { variant: 'facebook' },
          style: {
            background: '#1877F2',
            color: 'white',
            boxShadow: '0px 3px 1px 1px rgba(8,92,201,1)',
            ':hover': {
              background: '#2988F3',
              opacity: 0.9
            }
          }
        }
      ]
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: 10
        }
      }
    },
    MuiPaper: {
      variants: [
        {
          props: { variant: 'container' },
          style: {
            padding: '0.5rem',
            marginBottom: '0.5rem',
            boxShadow: theme.shadows[1]
          }
        }
      ]
    },
    MuiSwitch: {
      styleOverrides: {
        thumb: {
          border: `1px solid ${grey[400]}`
        }
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          // removes annoying border
          '&:before': {
            display: 'none'
          }
        }
      }
    }
  }
});

export default theme;
