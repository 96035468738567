import { Backdrop, CircularProgress, Stack, Typography } from '@mui/material';
import React from 'react';

const Loader = () => (
  <Backdrop open>
    <Stack gap={16} alignItems="center">
      <CircularProgress color="primary" thickness={3} size={100} />
      <Typography color="primary" variant="demiBold">
        Please wait...
      </Typography>
    </Stack>
  </Backdrop>
);

export default Loader;
